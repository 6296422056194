import { buscarValorPorCaminhoConcatenado } from "./buscar-valor-por-caminhos-concatenados";

export function ordenarPorQueries(queries: string[], data: any[]): any[] {
  return data.slice().sort((a: any, b: any) => {
    for (const query of queries) {
      const direcao = query[0];
      const propiedadeCaminho = query.slice(1);
      const valorA = buscarValorPorCaminhoConcatenado(a, propiedadeCaminho);
      const valorB = buscarValorPorCaminhoConcatenado(b, propiedadeCaminho);

      if (valorA === null && valorB === null) {
        continue;
      } else if (valorA === null) {
        return direcao === '+' ? 1 : -1;
      } else if (valorB === null) {
        return direcao === '+' ? -1 : 1;
      }

      if (valorA < valorB) {
        return direcao === '+' ? -1 : 1;
      } else if (valorA > valorB) {
        return direcao === '+' ? 1 : -1;
      }
    }
    return 0;
  });
}
